<template>
  <ui-component-modal
    modalTitle="Delete pricing &amp; yield settings"
    :buttonLabelConfirm="'Delete'"
    confirmButtonCssClass="is-danger"
    :isSaving="isDeleting"
    :isSavingSuccess="isDeletingSuccess"
    :isSavingError="isDeletingError"
    :hideFooterButtons="isDeletingError || isDeletingSuccess"
    :disableSaveButton="!isDeleteConfirmed"
    :onClickCancel="onClickCancel"
    :onClickSave="deleteYieldSettings"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div>
        <div class="has-margin-bottom is-size-5 has-text-centered">
          Are you sure you want to delete these settings?
        </div>
        <div class="has-text-centered">
          <BaseCheckbox
            v-model="isDeleteConfirmed"
            class="has-text-left"
            :label="'Yes, I am sure!'"
          />
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import yieldProvider from '@/providers/yield'

const BaseCheckbox = () => import('@/components/UI/Form/BaseCheckbox')

export default {
  components: {
    BaseCheckbox,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    yieldSetting: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isDeleteConfirmed: false,
      isDeleting: false,
      isDeletingSuccess: false,
      isDeletingError: false,
    }
  },

  computed: {},

  created() {},

  methods: {
    deleteYieldSettings() {
      let self = this

      if (!self.isDeleting) {
        self.isDeleting = true

        yieldProvider.methods
          .deleteYieldSettings(self.yieldSetting.Id)
          .then((response) => {
            if (response.status === 204) {
              self.isDeletingSuccess = true

              setTimeout(() => {
                self.onClickCancel()
                self.$emit('yieldSettingDeleted', self.yieldSetting.Id)
              }, 1500)
            }
          })
          .catch((err) => {
            //console.log(err)
            self.isDeletingError = true
          })
          .finally(() => {
            self.isDeleting = false
          })
      }
    },
  },
}
</script>
